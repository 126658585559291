<template>
  <div class="">
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      label-width="120px"
      class="demo-ruleForm"
    >
      <el-form-item label="Department" prop="department_id">
        <el-select
          v-model="ruleForm.department_id"
          placeholder="Select Department"
          :remote-method="fetchDepartments"
          :loading="loading"
        >
          <el-option
            v-for="item in departments"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Select User" prop="user_id">
        <el-select
          v-model="ruleForm.user_id"
          filterable
          remote
          collapse-tags
          reserve-keyword
          placeholder="Type Username"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in userLists"
            :key="item.user_id"
            :label="item.user_name"
            :value="item.user_id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Name" prop="name">
        <el-input v-model="ruleForm.name" />
      </el-form-item>
      <el-form-item label="Title" prop="title">
        <el-input v-model="ruleForm.title" />
      </el-form-item>
      <el-form-item label="Body" prop="message">
        <el-input v-model="ruleForm.message" type="textarea" rows="6" />
      </el-form-item>
      <div class="form-row">
        <el-form-item label="Status" prop="status">
          <el-select v-model="ruleForm.status" placeholder="Select Status">
            <el-option label="Open" value="open" />
            <el-option label="In Progress" value="in_progress" />
            <el-option label="Answered" value="answered" />
            <el-option label="On Hold" value="on_hold" />
            <el-option label="Closed" value="closed" />
          </el-select>
        </el-form-item>
        <el-form-item label="Urgency" prop="urgency">
          <el-select v-model="ruleForm.urgency" placeholder="Select Urgency">
            <el-option label="Low" value="low" />
            <el-option label="Medium" value="medium" />
            <el-option label="High" value="high" />
          </el-select>
        </el-form-item>
        <el-form-item label="Assign To" prop="staff_id">
          <el-select
            v-model="ruleForm.staff_id"
            placeholder="Select Staff"
            :remote-method="fetchStaffs"
            :loading="loading"
          >
            <el-option
              v-for="staff in staffs"
              :key="staff.id"
              :label="staff.name"
              :value="staff.id"
            />
          </el-select>
        </el-form-item>
      </div>
      <el-form-item>
        <el-button
          :loading="loading"
          type="primary"
          @click="submitForm('ruleForm')"
        >
          Create
        </el-button>
        <el-button @click="resetForm('ruleForm')"> Reset </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  SEARCH_USERS,
  SEND_NOTIFICATION,
  GET_DEPARTMENTS,
  GET_STAFFS,
  CREATE_TICKET,
} from "@core/services/api";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      ruleForm: {
        name: "",
        title: "",
        user_id: "",
        message: "",
        status: "",
        urgency: "",
        staff_id: "",
        bulk: false,
        users: [],
        departments: [],
        staffs: [],
        department_id: "",
      },
      rules: {
        name: [
          { required: true, message: "Please input name", trigger: "blur" },
          { max: 150, message: "Length should be 150 char", trigger: "blur" },
        ],
        title: [
          { required: true, message: "Please input title", trigger: "blur" },
          { max: 150, message: "Length should be 150 char", trigger: "blur" },
        ],
        message: [
          { required: true, message: "Please input message", trigger: "blur" },
          { max: 500, message: "Length should be 500 char", trigger: "blur" },
        ],
        department_id: [
          {
            required: true,
            message: "Please select a department",
            trigger: "change",
          }, // Required department selection
        ],
        staff_id: [
          {
            required: true,
            message: "Please select a staff member",
            trigger: "change",
          }, // Required staff selection
        ],
        urgency: [
          {
            required: true,
            message: "Please select urgency",
            trigger: "change",
          }, // Required urgency selection
        ],
        status: [
          {
            required: true,
            message: "Please select status",
            trigger: "change",
          }, // Required status selection
        ],
      },
      userLists: [],
      departments: [],
      staffs: [],
      user_id: "",
      department_id: "",
      loading: false,
      isp_id: JSON.parse(localStorage.getItem("userData")),
    };
  },
  methods: {
    fetchDepartments() {
      this.loading = true;
      this.$http
        .get(process.env.VUE_APP_BASEURL + GET_DEPARTMENTS, {
          params: {
            isp_id: this.isp_id.id,
          },
        })
        .then((res) => {
          console.log("data:", res.data);
          console.log("isp:", this.isp_id);
          this.departments = res.data.data.departments;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchStaffs() {
      this.loading = true;
      this.$http
        .get(process.env.VUE_APP_BASEURL + GET_STAFFS, {
          params: {
            isp_id: this.isp_id.id,
          },
        })
        .then((res) => {
          console.log("data:", res.data);
          console.log("isp:", this.isp_id);
          this.staffs = res.data.data.staffs;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.isp_id = this.isp_id.id;
          this.loading = true;

          // Sending post request
          this.$http
            .post(process.env.VUE_APP_BASEURL + CREATE_TICKET, this.ruleForm)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "New Ticket Created successfully!",
                  variant: "success",
                  icon: "fas fa-check-circle",
                },
              });
              this.loading = false; // Stop loading once the request is successful
              this.resetForm(formName);
            })
            .catch((err) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Failed to create Ticket.",
                  variant: "danger",
                  icon: "fas fa-times-circle",
                },
              });
              console.log("Error: ", err);
              this.loading = false; // Stop loading even if there is an error
            });
        } else {
          console.log("Form validation failed!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    remoteMethod(query) {
      if (query !== "" && query.length > 3) {
        this.loading = true;
        this.$http
          .get(process.env.VUE_APP_BASEURL + SEARCH_USERS, {
            params: {
              search_query: query,
              isp_id: this.isp_id.id,
            },
          })
          .then((res) => {
            console.log("data:", res.data.data.users);
            this.userLists = res.data.data.users;
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  mounted() {
    this.fetchDepartments(); // Fetch departments on component mount
    this.fetchStaffs(); // Fetch departments on component mount
  },
};
</script>
